//animation images
import desMe from "../assets/desMe.webp";
import frozen from "../assets/frozen.webp";
import panda from "../assets/panda4.webp";
import hotel from "../assets/hotel4.webp";

//comedy images
import theOffice from "../assets/theOffice.webp";
import friends from "../assets/friends.webp";
import parksAndRec from "../assets/parksAndRecreation.webp";
import brooklynNineNine from "../assets/brooklynNineNine.webp";
import seinfeld from "../assets/seinfeld.webp";
import theGoodPlace from "../assets/theGoodPlace.webp";
import modernFamily from "../assets/modernFamily.webp";
import howIMetYourMother from "../assets/himym.webp";

//k-drama images
import squidGame from "../assets/squidGame.webp";
import sweetHome from "../assets/sweetHome.webp";
import xMen97 from "../assets/XMen97.webp";
import gravityFalls from "../assets/gravityFalls.webp";
import soloLeveling from "../assets/soloLeveling.webp";
import adventureTime from "../assets/adventureTime.webp";
import pachinko from "../assets/pachinko.webp";

export const animations = [
  {
    image: desMe,
    title: "Despicable Me 4",
    rate: "N/A",
    year: "2024",
    url: "https://www.imdb.com/title/tt7510222/?ref_=nv_sr_srsg_1_tt_6_nm_1_in_0_q_des",
  },
  {
    image: hotel,
    title: "Hotel Transylvania",
    rate: "6.0",
    year: "2022",
    url: "https://www.imdb.com/title/tt9848626/?ref_=nv_sr_srsg_3_tt_8_nm_0_in_0_q_Hotel%2520Transylvania",
  },
  {
    image: frozen,
    title: "Frozen",
    rate: "7.4",
    year: "2013",
    url: "https://www.imdb.com/title/tt2294629/?ref_=nv_sr_srsg_3_tt_8_nm_0_in_0_q_Frozen%2520",
  },
  {
    image: panda,
    title: "Kung Fu Panda 4",
    rate: "N/A",
    year: "2024",
    url: "https://www.imdb.com/title/tt21692408/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Kung%2520Fu%2520Panda%25204",
  },
  {
    image: desMe,
    title: "Despicable Me 4",
    rate: "N/A",
    year: "2024",
    url: "https://www.imdb.com/title/tt7510222/?ref_=nv_sr_srsg_1_tt_6_nm_1_in_0_q_des",
  },
  {
    image: hotel,
    title: "Hotel Transylvania",
    rate: "6.0",
    year: "2022",
    url: "https://www.imdb.com/title/tt9848626/?ref_=nv_sr_srsg_3_tt_8_nm_0_in_0_q_Hotel%2520Transylvania",
  },
  {
    image: frozen,
    title: "Frozen",
    rate: "7.4",
    year: "2013",
    url: "https://www.imdb.com/title/tt2294629/?ref_=nv_sr_srsg_3_tt_8_nm_0_in_0_q_Frozen%2520",
  },
  {
    image: panda,
    title: "Kung Fu Panda 4",
    rate: "N/A",
    year: "2024",
    url: "https://www.imdb.com/title/tt21692408/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Kung%2520Fu%2520Panda%25204",
  },
  {
    image: frozen,
    title: "Frozen II",
    rate: "6.8",
    year: "2019",
    url: "https://www.imdb.com/title/tt2294629/?ref_=nv_sr_srsg_3_tt_8_nm_0_in_0_q_Frozen%2520",
  },
];

export const kDrama = [
  {
    image: squidGame,
    title: "Squid Game",
    rate: "8.0",
    year: "2021",
    url: "https://www.imdb.com/title/tt10919420/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Squid%2520Game",
  },
  {
    image: sweetHome,
    title: "Sweet Home",
    rate: "7.3",
    year: "2020",
    url: "https://www.imdb.com/title/tt11612120/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Sweet%2520Home",
  },
  {
    image: xMen97,
    title: "X-Men '97",
    rate: "8.8",
    year: "2024",
    url: "https://www.imdb.com/title/tt16026746/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_X-Men%2520%2797",
  },
  {
    image: gravityFalls,
    title: "Gravity Falls",
    rate: "8.9",
    year: "2012",
    url: "https://www.imdb.com/title/tt1865718/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Gravity%2520Falls",
  },
  {
    image: soloLeveling,
    title: "Solo Leveling",
    rate: "8.3",
    year: "2024",
    url: "https://www.imdb.com/title/tt21209876/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Solo%2520Leveling",
  },
  {
    image: adventureTime,
    title: "Adventure Time",
    rate: "8.6",
    year: "2010",
    url: "https://www.imdb.com/title/tt1305826/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Adventure%2520Time",
  },
  {
    image: pachinko,
    title: "Pachinko",
    rate: "8.3",
    year: "2022",
    url: "https://www.imdb.com/title/tt8888462/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Pachinko",
  },
  {
    image: soloLeveling,
    title: "Solo Leveling",
    rate: "8.3",
    year: "2024",
    url: "https://www.imdb.com/title/tt21209876/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Solo%2520Leveling",
  },
  {
    image: adventureTime,
    title: "Adventure Time",
    rate: "8.6",
    year: "2010",
    url: "https://www.imdb.com/title/tt1305826/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Adventure%2520Time",
  },
  {
    image: pachinko,
    title: "Pachinko",
    rate: "8.3",
    year: "2022",
    url: "https://www.imdb.com/title/tt8888462/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Pachinko",
  },
];

export const comedy = [
  {
    image: theOffice,
    title: "The Office",
    rate: "9.0",
    year: "2005",
    url: "https://www.imdb.com/title/tt0386676/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_The%2520Office",
  },
  {
    image: friends,
    title: "Friends",
    rate: "8.9",
    year: "1994",
    url: "https://www.imdb.com/title/tt0108778/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Friends",
  },
  {
    image: parksAndRec,
    title: "Parks and Recreation",
    rate: "8.6",
    year: "2009",
    url: "https://www.imdb.com/title/tt1266020/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Parks%2520and%2520Recreation",
  },
  {
    image: brooklynNineNine,
    title: "Brooklyn Nine-Nine",
    rate: "8.4",
    year: "2013",
    url: "https://www.imdb.com/title/tt2467372/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Brooklyn%2520Nine-Nine",
  },
  {
    image: seinfeld,
    title: "Seinfeld",
    rate: "8.9",
    year: "1989",
    url: "https://www.imdb.com/title/tt0098904/?ref_=nv_sr_srsg_0_tt_5_nm_3_in_0_q_Seinfeld",
  },
  {
    image: theGoodPlace,
    title: "The Good Place",
    rate: "8.2",
    year: "2016",
    url: "https://www.imdb.com/title/tt4955642/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_The%2520Good%2520Place",
  },
  {
    image: modernFamily,
    title: "Modern Family",
    rate: "8.5",
    year: "2009",
    url: "https://www.imdb.com/title/tt1442437/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Modern%2520Family",
  },
  {
    image: howIMetYourMother,
    title: "How I Met Your Mother",
    rate: "8.3",
    year: "2005",
    url: "https://www.imdb.com/title/tt0460649/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_How%2520I%2520Met%2520Your%2520Mother",
  },
  {
    image: theOffice,
    title: "The Office",
    rate: "9.0",
    year: "2005",
    url: "https://www.imdb.com/title/tt0386676/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_The%2520Office",
  },
  {
    image: friends,
    title: "Friends",
    rate: "8.9",
    year: "1994",
    url: "https://www.imdb.com/title/tt0108778/?ref_=nv_sr_srsg_0_tt_8_nm_0_in_0_q_Friends",
  },
];
